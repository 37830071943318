<template>
  <div id="chart">
    <apexchart type="radar" width="570" height="500" :options="chartOptions" :series="series"></apexchart>
  </div>

</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      text : '선택없음',
      series: [{
        name: '2022-06-10',
        data: [28, 20, 10, 10, 10, 20, 3],
      }, {
        name: '2022-06-07',
        data: [12, 3, 4, 8, 20, 18, 8],
      }, {
        name: '2022-06-04',
        data: [4, 16, 18, 13, 23, 10, 3],
      }],
      chartOptions: {
        chart: {
          height: 500,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
          events: {
            legendClick: this.setClickedData
          }
        },
        legend: {
          show: true,
          position: 'top',
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
          fontSize: '14px',
          fontFamily: 'NotoSansKR_M, Arial',
          labels: {
            colors: ["#2E8AF4", "#B947EE", "#5AC820"],
            useSeriesColors: false
          },
          markers: {
            width: 50,
            height: 15,
            strokeWidth: 2,
            strokeColor: ["#2E8AF4", "#B947EE", "#5AC820"],
            fillColors: ["#ffffff", "#ffffff", "#ffffff"],
            radius: 0,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 13,
            vertical: 0
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
          show: true,
          colors: ["#2E8AF440", "#B947EE40", "#5AC82040"],
          dashArray: 0
        },
        fill: {
          colors: ["#2E8AF405", "#B947EE05", "#5AC82005"]
        },
        markers: {
          size: 0,
          hover: {
            size: 10
          }
        },
        xaxis: {
          categories: ['일상생활활동', '복합적일상생활활동', '대,소근육운동능력', '사회성활동', '학업', '의사소통', '자기조절'],
          labels: {
            show: true,
            style: {
              colors: ["#71717A", "#71717A", "#71717A", "#71717A", "#71717A", "#71717A", "#71717A"],
              fontSize: "16px",
              fontFamily: 'NotoSansKR_M, Arial',
            }
          }
        },
        yaxis: {
          tickAmount: 6,
        },
      },
    }
  },
  created() {
    window.setClickedData = this.setClickedData;
  },
  methods: {
    setClickedData(chartContext, seriesIndex, config) {
      // console.log(chartContext);
      // console.log(seriesIndex);
      // console.log(config);
      // console.log(config.config.fill.colors[seriesIndex]);
      config.config.fill.colors[seriesIndex] = 'red'
      this.text = seriesIndex;
      // console.log(config.config.fill.colors[seriesIndex]);
    }
  }
}
</script>

<style scoped>

</style>